let sectionVideo = document.querySelectorAll('.block_hero_home');

if (sectionVideo) {
    sectionVideo.forEach(function (el) {
        let video = el.querySelector('video');

        if (video) {
            let button = el.querySelector('.play-button');
            if (button) {
                button.addEventListener('click', toggleVideoSound);
            }

            //Pause video when scrolled out of viewport
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: video,
                    start: 'top center',
                    end: 'bottom 15%',
                    pin: false,
                    scrub: true,
                    toggleActions: 'play reverse play reverse',
                    onEnter: () => {
                        if (video) {
                            video.play()
                        }
                    },
                    onEnterBack: () => {
                        if (video) {
                            video.play()
                        }
                    },
                    onLeave: () => {
                        if (video) {
                            video.pause()
                        }
                    },
                    onLeaveBack: () => {
                        if (video) {
                            video.pause()
                        }
                    }
                }
            });
        }

        //Function to toggle video volume
        function toggleVideoSound() {
            let video = el.querySelector('video');
            let button = el.querySelector('.play-button .icon-text');

            if (video.muted === true) {
                video.muted = false;
                button.innerText = button.getAttribute('data-unmuted');
            } else {
                video.muted = true;
                button.innerText = button.getAttribute('data-muted');
            }
        }
    });
}
